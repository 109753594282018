<template>
  <mega-modal
    ref="modal-2"
    class="modal-sm"
    :active="modal"
    @onClose="modal = false"
  >
    <mega-header :title="$t('set_owner')" class="bg-lighter">
      <span class="text-muted">{{ $t("business_owner_desc") }}</span>
    </mega-header>

    <div class="card-body">
      <mega-input label="Email" :valid="validEmail" v-model="email" />
    </div>

    <div class="card-body">
      <div class="navbar">
        <div class="navbar-group ml-auto">
          <mega-button class="btn-white mr-2" @click="modal = false">{{
            $t("close")
          }}</mega-button>
          <mega-button
            class="btn-primary mr-2"
            :disabled="!email || !validEmail"
            @click="submit"
            >{{ $t("business_owner_new") }}</mega-button
          >
        </div>
      </div>
    </div>
  </mega-modal>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      arp: undefined,
      modal: false
    };
  },
  computed: {
    validEmail() {
      let valid = true;
      let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/;

      this.$check(this.email).then(
        a => a.length > 0 && !mailFormat.test(a.toLowerCase()),
        () => {
          valid = false;
        }
      );

      return valid;
    }
  },
  methods: {
    submit() {
      let data = {
        idt_arp: this.arp,
        email: this.email
      };

      this.$api.v2.put("/arp/owner", data).then(() => {
        this.modal = false;
        this.$alert.success("Admin was invited");
      });
    },

    open(arp) {
      this.email = "";
      this.arp = arp;
      this.modal = true;
    }
  }
};
</script>
